* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

#root {
    height: 100vh;
    width: 100vw;
}
